import { Palette } from './types'

export let customPalette: Palette

export function cachePalette(palette?: Palette): void {
  customPalette = {
    title: '#7789F6',
    button: '#7789F6',
    link: '#7789F6',
    ...palette,
  }
}
