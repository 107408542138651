import { Palette } from '../types'

const cssTemplate = (palette?: Palette) => {
  const { title, button, link } = palette
  return `
  .sf-consent {
    bottom: 0;
    left: 0;
    width: auto;
    height: auto;
    z-index: 1000000;
    position: fixed;
    font-family: Arial, sans-serif;
  }

  .sf-consent__content {
    padding: 24px;
    min-height: 250px;
    max-height: 100vh;
    width: 375px;
    position: fixed;
    left: 40px;
    bottom: 40px;
    overflow: auto;
    border-radius: 5px;
    margin: 0 auto;
    background-color: rgb(255, 255, 255);
    box-shadow: -2px 2px 5px 0 rgba(53, 53, 53, 0.45);
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-animation-name: sfSlideIn;
    -webkit-animation-duration: 0.6s;
    animation-name: sfSlideIn;
    animation-duration: 0.6s;
    text-align: center;
    font-weight: 400;
  }

.sf-consent__header {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.sf-consent__header__title {
  color: ${title};
  margin: auto;
  margin-bottom: 25px;
  font-size: 20px;
  font-weight: 700;
}

.sf-consent__content .modal-main {
  -webkit-box-flex: 1;
  flex: 1;
  // overflow: auto;
  color: #808080;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}

.sf-consent__content .inputs {
  text-align: left;
  margin: auto;
}

.sf-consent__content .inputs .description {
  padding: 7px 0 15px 54px;
  font-family: Arial;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2b2b2b;
}

.sf-consent__content .modal-main a {
   cursor: pointer;
   color: ${link};
   padding: 2px;
   border-bottom: 1px solid ${link};
   font-size: 14px;
}

.sf-consent__content .modal-main a:hover {
  text-decoration: none
}

#privacy-policy {
  margin-top: 15px;
  margin-bottom: 15px;
  text-decoration: none;
  font-size: 12px;
}

.sf-consent__content .modal-main p {
    font-size: 14px;
    color: #000000;
    padding: 0px;
    margin: 0px;
}

.sf-consent__content .modal-main button {
  -webkit-tap-highlight-color: transparent;
  -webkit-box-direction: normal;
  box-sizing: border-box;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border: 1px solid transparent;
  border-radius: 3px;
  font-size: 14px;
  padding: 9px 15px;
  margin: 24px auto;
  -webkit-transition: all 0.3s ease-in-out;
  letter-spacing: 1px;
}

.sf-consent__content .modal-main button.primary {
  background-color: ${button};
  border-color: ${button};
  color: #ffffff;
  margin-left: 15px;
}
.sf-consent__content .modal-main button.default {
  color: ${button};
  background-color: #ffffff;
  border-color: ${button};
}

.sf-consent__content > footer {
  padding: 0;
}

.sf_close {
  position: absolute;
  right: 15px;
  top: 15px;
}

.sf_close :hover {
  cursor: pointer;
}

@-webkit-keyframes sfFadeIn {
  from {opacity: 0}
  to {opacity: 1}
}

@keyframes sfFadeIn {
  from {opacity: 0}
  to {opacity: 1}
}

@-webkit-keyframes sfSlideIn {
  from {bottom: -500px; opacity: 0}
  to {bottom: 40px; opacity: 1}
}

@keyframes sfSlideIn {
  from {bottom: -500px; opacity: 0}
  to {bottom: 40px; opacity: 1}
}

input[type='checkbox'] {
  display: none;
  cursor: pointer;
}
input[type='checkbox']:focus, input[type='checkbox']:active {
  outline: none;
}
input[type='checkbox'] + label {
  cursor: pointer;
  display: inline-block;
  position: relative;
  padding-left: 8px;
}

input[type='checkbox'] + label:before {
  background-color: #fafafa;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
input[type='checkbox'] + label:after {
  color: #fff;
}
input[type='checkbox']:checked + label:before {
  -moz-box-shadow: inset 0 0 0 10px ${title};
  -webkit-box-shadow: inset 0 0 0 10px ${title};
  box-shadow: inset 0 0 0 10px ${title};
}

/*Toggle Specific styles*/
input[type='checkbox'].toggle {
  display: inline-block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 33px;
  height: 18px;
  background-color: #fafafa;
  position: relative;
  top: 6px;
  border: 1px solid  #808080;
  -moz-border-radius: 30px;
  -webkit-border-radius: 30px;
  border-radius: 30px;
  @include box-shadow(none);
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
input[type='checkbox'].toggle:hover:after {
  background-color: #c7c7c7;
}
input[type='checkbox'].toggle:after {
  content: '';
  display: inline-block;
  position: absolute;
  width: 14px;
  height: 14px;
  background-color: #aeaeae;
  top: 1px;
  left: 1px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
input[type='checkbox']:checked.toggle {
  -moz-box-shadow: inset 0 0 0 15px ${title};
  -webkit-box-shadow: inset 0 0 0 15px ${title};
  box-shadow: inset 0 0 0 15px ${title};
}
input[type='checkbox']:checked.toggle:after {
  left: 16px;
  background-color: #fff;
}

.sf_title {
  font-family: Arial;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2b2b2b;
}

@media only screen and (max-width: 600px) {
  .sf-consent__content {
    max-width: 90vw;
    min-height: 0;
    padding: 20px 10px 20px 10px;
    position: fixed;
    bottom: 10px;
    left: 10px;
    background-color: rgb(255, 255, 255);
    box-shadow: -2px 2px 5px 0 rgba(53, 53, 53, 0.45);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-animation-name: sfSlideIn;
    -webkit-animation-duration: 0.6s;
    animation-name: sfSlideIn;
    animation-duration: 0.6s;
  }

  .sf-consent__header__title {
    display: none;
  }

  .modal-main {
    height: 200px;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
  }

  .sf-consent__content .modal-main button {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .sf-consent__content footer {
    margin-top: 10px;
  }
  #privacy-policy {
    margin-top: 10px;
  }
}
`
}
export default cssTemplate
