import { isOnBlocklist } from './utils'
import { BLOCKED_TYPE } from './variables'

const createElementBackup = document.createElement

// Monkey patch the createElement method to prevent dynamic scripts from executing
document.createElement = function (...args) {
  // If this is not a script tag, bypass
  if (args[0].toLowerCase() !== 'script') return createElementBackup.bind(document)(...args)

  const scriptElt = createElementBackup.bind(document)(...args)
  const originalSetAttribute = scriptElt.setAttribute.bind(scriptElt)

  // Define getters / setters to ensure that the script type is properly set
  try {
    Object.defineProperties(scriptElt, {
      src: {
        get() {
          return scriptElt.getAttribute('src')
        },
        set(value) {
          if (isOnBlocklist(value, scriptElt.type)) {
            originalSetAttribute('type', BLOCKED_TYPE)
          }
          originalSetAttribute('src', value)
          return true
        },
      },
      type: {
        set(value) {
          const typeValue = isOnBlocklist(scriptElt.src, scriptElt.type) ? BLOCKED_TYPE : value
          originalSetAttribute('type', typeValue)
          return true
        },
      },
    })

    // Monkey patch the setAttribute function so that the setter is called instead
    scriptElt.setAttribute = function (name: string, value) {
      if (name === 'type' || name === 'src') scriptElt[name] = value
      else HTMLScriptElement.prototype.setAttribute.call(scriptElt, name, value)
    }
  } catch (error) {
    console.warn(
      'SixFifty: unable to prevent script execution for script src ',
      scriptElt.src,
      '.\n',
      'A likely cause would be because you are using a third-party browser extension that monkey patches the "document.createElement" function.',
    )
  }

  return scriptElt
}
