import {initClient, client} from './client'
import {consenter} from './consenter'
import {cachePalette, customPalette} from './palette'
import {noticeTemplate, cssTemplate, preferencesTemplate} from './templates'
import {
  Content,
  ScriptTypes,
  SixFifty,
} from './types'
import {
  ACCEPT_ALL,
  ACCEPT_NONE,
  allScripts,
  SFC_CLOSE,
} from './variables'
import unblock from './unblock'
import {parsedDocumentCookies} from './utils'
import {observer} from './observer'
import {Variables} from 'graphql-request/dist/types'
import _ from 'lodash'

const SIXFIFTY: SixFifty = window['_650']
let customContent: Content

export function init(): void {
  initClient(SIXFIFTY.authKey)
  if (!consenter.preferencesWithinTwoWeeks) {
    if (consenter.requiresConsent()) {
      showConsent()
    } else {
      getCookieDefinitionsAndKillBadCookies()
    }
  }
}

const scripts = SIXFIFTY.config.scripts
scripts.forEach(script => {
  allScripts[script.category].push(script.initiatingDomain)
})
observer.observe(window.document, {
  childList: true,
  subtree: true,
})

async function getDomain(): Promise<void> {
  const host = SIXFIFTY.domainId
  const language = navigator.language.toLowerCase()
  await fetch(`__endpoint__/get_domain/${host}/${language}`, {
    method: 'GET', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    headers: {
      'Cross-Origin-Resource-Policy': 'cross-origin',
      'Content-Type': 'application/json',
      'X-SixFifty-UC': SIXFIFTY.authKey,
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  }).then((response) => response.json())
    .then((domain) => {
      // @ts-ignore
      consenter.storage.setItem('sf_consent_cookie_definitions', JSON.stringify(domain.data.cookies))
      const content = domain.data.content
      const palette = domain.data.domain.palette
      cachePalette(palette)
      customContent = content
      injectSDK()
    });
}

async function getCookieDefinitionsAndKillBadCookies(): Promise<void> {
  const host = SIXFIFTY.domainId
  const storage = window.localStorage
  const cookieDefinitionsLocal = JSON.parse(storage.sf_consent_cookie_definitions)
  if (cookieDefinitionsLocal == null) {
    await fetch(`__endpoint__/get_cookie_definitions/${host}`, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        'Cross-Origin-Resource-Policy': 'cross-origin',
        'Content-Type': 'application/json',
        'X-SixFifty-UC': SIXFIFTY.authKey,
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    }).then((response) => response.json())
      .then((data) => {
        const cookieDefinitions = _.keyBy(data.domain.cookies, 'cookieName')
        storage.setItem('sf_consent_cookie_definitions', JSON.stringify(cookieDefinitions))
        killBadCookies(cookieDefinitions)
      });
  } else {
    killBadCookies(cookieDefinitionsLocal)
  }
}

function killBadCookies(cookieDefinitions) {
  const docCookies = parsedDocumentCookies()
  const newCookies = _.reduce(
    docCookies,
    (result, docCookieValue, docCookieName) => {
      const cookieDefinition = cookieDefinitions[docCookieName]
      if (!cookieDefinition || consenter.preferences[cookieDefinition.category]) {
        result += ` ${docCookieName}=${docCookieValue};`
      } else {
        result += ` ${docCookieName}=${docCookieValue};expires=Wed, 21 Oct 2015 12:00:00 UTC;`
      }
      return result
    },
    '',
  )
  document.cookie = newCookies.trim()
}

function injectSDK(): void {
  const SDK = document.getElementById('sf-sdk')
  if (!SDK) {
    const entry = document.getElementsByTagName('script')[0]
    const style = document.createElement('style')
    style.appendChild(document.createTextNode(cssTemplate(customPalette)))
    entry.parentNode.insertBefore(style, entry)
    const div = document.createElement('div')
    div.setAttribute('id', 'sf-sdk')
    document.body.appendChild(div)
  }
}

function handleSubmit() {
  let div: HTMLDivElement
  const id__ = this.getAttribute('id')
  if (id__ == ACCEPT_ALL) {
    div = <HTMLDivElement>document.getElementById('sf-consent__id')
    createTransaction(true, true, true)
  } else if (id__ == ACCEPT_NONE) {
    div = <HTMLDivElement>document.getElementById('sf-consent__id')
    createTransaction(false, false, false)
  } else {
    div = <HTMLDivElement>document.getElementById('sf-preferences__id')
    const analyticsElement: HTMLInputElement = div.querySelector('input[name="analytics"]')
    const functionalElement: HTMLInputElement = div.querySelector('input[name="functional"]')
    const advertisingElement: HTMLInputElement = div.querySelector('input[name="advertising"]')
    createTransaction(analyticsElement.checked, functionalElement.checked, advertisingElement.checked)
  }
  div.style.display = 'none'
}

function handleClose(): void {
  let div: HTMLDivElement
  const id__ = this.getAttribute('id')
  if (id__ == SFC_CLOSE) {
    div = <HTMLDivElement>document.getElementById('sf-consent__id')
  } else {
    div = <HTMLDivElement>document.getElementById('sf-preferences__id')
  }
  div.style.display = 'none'
}

function createDiv(id: string): HTMLDivElement {
  let div: HTMLDivElement
  div = <HTMLDivElement>document.getElementById(id)
  if (!div) {
    div = document.createElement('div')
    div.setAttribute('id', id)
    div.setAttribute('class', 'sf-consent')
    div.setAttribute('tabindex', '0')
    const container = window.document.getElementById('sf-sdk')
    container.appendChild(div)
  }
  return div
}

export async function showConsent(): Promise<void> {
  await getDomain().then(viewConsent)
}

export function viewConsent() {
  let div = createDiv('sf-consent__id')
  div.innerHTML = noticeTemplate(customContent)
  const openDiv = <HTMLDivElement>document.getElementById('sf-preferences__id')
  if (openDiv && !openDiv.hidden) {
    openDiv.style.display = 'none'
  }
  div.style.display = 'flex'
  for (let _id of [ACCEPT_ALL, ACCEPT_NONE]) {
    const el = document.getElementById(_id)
    el.addEventListener('click', handleSubmit)
  }
  if (!consenter.requiresConsent()) {
    const close = document.getElementById('sfc-close')
    close.addEventListener('click', handleClose)
  }
}

export function showPreferences(): void {
  // TODO: check if it exists first
  let div = createDiv('sf-preferences__id')
  div.innerHTML = preferencesTemplate(customContent)
  const openDiv = <HTMLDivElement>document.getElementById('sf-consent__id')
  if (openDiv && !openDiv.hidden) {
    openDiv.style.display = 'none'
  }
  div.style.display = 'flex'
  const el = document.getElementById('sf-accept')
  el.addEventListener('click', handleSubmit)
  if (!consenter.requiresConsent()) {
    const close = document.getElementById('sfp-close')
    close.addEventListener('click', handleClose)
  }
}

export async function createTransaction(
  acceptAnalytics: boolean,
  acceptFunctional: boolean,
  acceptAdvertising: boolean,
) {
  const variables: Variables = {
    consenter: consenter.anonymousId,
    ac: acceptAnalytics,
    fc: acceptFunctional,
    tc: acceptAdvertising,
  }
  const host = SIXFIFTY.domainId

  await fetch(`__endpoint__/create_transaction/${JSON.stringify(variables)}/${host}`, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    headers: {
      'Cross-Origin-Resource-Policy': 'cross-origin',
      'Content-Type': 'application/json',
      'X-SixFifty-UC': SIXFIFTY.authKey,
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  }).then((response) => response.json())
    .then((data) => {
      const preferences = data.createTransaction.transaction
      consenter.preferences = preferences
      consenter.persist()
      blockCheck()
      getCookieDefinitionsAndKillBadCookies()
    });
}

function blockCheck() {
  if (consenter.allowFunctional()) {
    unblock(...allScripts[ScriptTypes.functional])
  }
  if (consenter.allowAdvertising()) {
    unblock(...allScripts[ScriptTypes.advertising])
  }
  if (consenter.allowAnalytics()) {
    unblock(...allScripts[ScriptTypes.analytics])
  }
}
