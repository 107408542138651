import {v4 as uuidv4} from 'uuid'
import {Preferences, ScriptTypes} from './types'
import {allScripts} from './variables'

const regExpEscape = (str: string) => str.replace(/[-[\]{}()*+!<=:?.\/\\^$|#\s,]/g, '\\$&')

// TODO? create a wrapper to localStorage with fallback to cookies, depending on what is available...
class Consenter {
  private storage: Storage
  private _preferences?: Preferences
  private _blacklist: RegExp[] = []
  private _previousVisitor: Boolean
  private _withinTwoWeeks: Boolean

  anonymousId: string

  constructor() {
    this.storage = window.localStorage
    let obj = this.storage.getItem('sf_consent')
    if (obj) {
      this._previousVisitor = true;
      let item = JSON.parse(obj)
      this.anonymousId = item.anonymousId
      this._preferences = item.preferences
    } else {
      this._previousVisitor = false;
      this.anonymousId = uuidv4()
    }
    this.withinTwoWeeks();
  }

  get blacklist(): RegExp[] {
    if (this._blacklist.length) {
      return this._blacklist
    }

    Object.keys(ScriptTypes).map(script => {
      if (!this.cookieTypeEnabled(ScriptTypes[script])) {
        this._blacklist.push(...allScripts[script].map(script => new RegExp(regExpEscape(script))))
      }
    })

    return this._blacklist
  }

  set blacklist(value: RegExp[]) {
    this._blacklist = value
  }

  get preferences(): Preferences {
    return (
      this._preferences || {
        analyticsCookies: false,
        functionalCookies: false,
        advertisingCookies: false,
      }
    )
  }

  get preferencesWithinTwoWeeks(): Boolean {
    return this._withinTwoWeeks;
  }

  set preferences(preferences: Preferences) {
    this._preferences = preferences
    this._blacklist = []
  }

  cookieTypeEnabled(scriptType: ScriptTypes): boolean {
    if (scriptType === ScriptTypes.necessary) {
      return true
    }
    return this.preferences[`${scriptType}Cookies`] == true
  }

  allowFunctional(): boolean {
    return this.cookieTypeEnabled(ScriptTypes.functional)
  }

  allowAnalytics(): boolean {
    return this.cookieTypeEnabled(ScriptTypes.analytics)
  }

  allowAdvertising(): boolean {
    return this.cookieTypeEnabled(ScriptTypes.advertising)
  }

  requiresConsent(): boolean {
    if (this._previousVisitor) {
      if (this._withinTwoWeeks) {
        return !this._preferences
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  withinTwoWeeks() {
    if (this._previousVisitor) {
      let startDate = new Date(this._preferences.modified).getTime();
      const today = new Date().getTime();
      let diff = (today - startDate) / 1000;
      // convert the difference from seconds to weeks
      diff /= (60 * 60 * 24 * 7);
      // is the difference within the last two weeks?
      this._withinTwoWeeks = diff <= 2
    }
  }

  private serialize() {
    return JSON.stringify({
      anonymousId: this.anonymousId,
      preferences: this.preferences || {},
    })
  }

  persist(): void {
    this.storage.setItem('sf_consent', this.serialize())
  }
}

export const consenter = new Consenter()
