import { GraphQLClient } from 'graphql-request'

const endpoint = '__endpoint__/graphql'

export let client: GraphQLClient

export function initClient(authKey: string): void {
  client = new GraphQLClient(endpoint, {
    headers: {
      'X-SixFifty-UC': authKey,
    },
  })
}
