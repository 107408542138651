import { backupScripts, BLOCKED_TYPE } from './variables'
import { willBeUnblocked } from './utils'
import { observer } from './observer'
import { consenter } from './consenter'

const URL_REPLACER_REGEXP = new RegExp('[|\\{}()[\\]^$+*?.]', 'g')

// Unblocks all (or a selection of) blacklisted scripts.
const unblock = function (...scriptUrlsOrRegexes: Array<string | RegExp>) {
  if (scriptUrlsOrRegexes.length < 1) {
    consenter.blacklist = []
  } else {
    if (consenter.blacklist) {
      consenter.blacklist = consenter.blacklist.filter(pattern =>
        scriptUrlsOrRegexes.every((urlOrRegexp: string | RegExp) => {
          if (typeof urlOrRegexp === 'string') return !pattern.test(urlOrRegexp)
          else if (urlOrRegexp instanceof RegExp) return pattern.toString() !== urlOrRegexp.toString()
        }),
      )
    }
  }

  // Parse existing script tags with a marked type
  const tags = document.querySelectorAll(`script[type="${BLOCKED_TYPE}"]`) as NodeListOf<HTMLScriptElement>
  tags.forEach(script => {
    if (willBeUnblocked(script)) {
      script.type = 'application/javascript'
      backupScripts.blacklisted.push(script)
      script.parentElement.removeChild(script)
    }
  })

  // Exclude 'whitelisted' scripts from the blacklist and append them to <head>
  let indexOffset = 0
  ;[...backupScripts.blacklisted].forEach((script, index) => {
    if (willBeUnblocked(script)) {
      const scriptNode = document.createElement('script')
      scriptNode.setAttribute('src', script.src)
      scriptNode.setAttribute('type', 'application/javascript')
      document.head.appendChild(scriptNode)
      backupScripts.blacklisted.splice(index - indexOffset, 1)
      indexOffset++
    }
  })

  // Disconnect the observer if the blacklist is empty for performance reasons
  if (consenter.blacklist && consenter.blacklist.length < 1) {
    observer.disconnect()
  }
}

export default unblock
