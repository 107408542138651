import { BLOCKED_TYPE, backupScripts } from './variables'
import { isOnBlocklist, isHTMLScriptElement } from './utils'

// Setup a mutation observer to track DOM insertion
// influenced by https://github.com/snipsco/yett/blob/cc45d750cadec8754c9b6e71a9b0c3ea0eff6b10/src/observer.js
export const observer = new MutationObserver(mutations => {
  mutations.forEach(mutation => {
    Array.from(mutation.addedNodes)
      .filter(isHTMLScriptElement)
      .forEach(script => {
        if (isOnBlocklist(script.src, script.type)) {
          backupScripts.blacklisted.push(script.cloneNode() as HTMLScriptElement)
          // Blocks inline script execution in Safari & Chrome
          script.type = BLOCKED_TYPE
          // Firefox has this additional event which prevents scripts from being executed
          const beforeScriptExecuteListener = (event: Event) => {
            // Prevent only marked scripts from executing
            if (script.getAttribute('type') === BLOCKED_TYPE) event.preventDefault()
            script.removeEventListener('beforescriptexecute', beforeScriptExecuteListener)
          }
          script.addEventListener('beforescriptexecute', beforeScriptExecuteListener)

          script.parentElement && script.parentElement.removeChild(script)
        }
      })
  })
})
