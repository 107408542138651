import { consenter } from '../consenter'
import footer from './footer'
import { Content } from '../types'

const preferencesTemplate = (content: Content) => `
  <div class="sf-consent__content">
      ${
        consenter.requiresConsent()
          ? ``
          : `<div id="sfp-close" class="sf_close"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="17" height="17" viewBox="0 0 17 17">
    <path d="M9.207 8.5l6.646 6.646-0.707 0.707-6.646-6.646-6.646 6.646-0.707-0.707 6.646-6.646-6.647-6.646 0.707-0.707 6.647 6.646 6.646-6.646 0.707 0.707-6.646 6.646z" fill="#000000" />
    </svg></div>`
      }
    <header class="sf-consent__header"><h4 class="sf-consent__header__title">${content.customizeTitle}</h4></header>
    <div class="modal-main">
    <div class="inputs">
        <input class='toggle' type="checkbox" name="necessary" checked disabled>
        <label class='sf_title'for="necessary">${content.necessaryTitle}</label><br>
        <div class="description">${content.necessaryDescription}</div>
        <input class='toggle' type="checkbox" name="functional" ${consenter.allowFunctional() ? 'checked' : ''}>
        <label class='sf_title' for="functional">${content.functionalTitle}</label><br>
        <div class="description">${content.functionalDescription}</div>
        <input class='toggle' type="checkbox" name="advertising" ${consenter.allowAdvertising() ? 'checked' : ''}>
        <label class='sf_title' for="advertising">${content.advertisingTitle}</label><br>
        <div class="description">${content.advertisingDescription}</div>
        <input class='toggle' type="checkbox" name="analytics" ${consenter.allowAnalytics() ? 'checked' : ''}>
        <label class='sf_title' for="analytics">${content.analyticsTitle}</label>
        <div class="description">${content.analyticsDescription}</div>
    </div>
    <div>
      <button class="default" onclick="SixFiftyConsent.viewConsent()">${content.backButton}</button>
      <button id="sf-accept" class="primary">${content.saveButton}</button>
    </div>
    </div>
    ${footer}
  </div>
`

export default preferencesTemplate
