export interface Content {
  title: string
  description: string
  accept: string
  decline: string
  link: string
  customizeTitle: string
  necessaryTitle: string
  necessaryDescription: string
  functionalTitle: string
  functionalDescription: string
  advertisingTitle: string
  advertisingDescription: string
  analyticsTitle: string
  analyticsDescription: string
  backButton: string
  saveButton: string
  policyHref: string
}

export interface Palette {
  title?: string
  button?: string
  link?: string
}

export enum ScriptTypes {
  advertising = 'advertising',
  analytics = 'analytics',
  functional = 'functional',
  necessary = 'necessary',
  targeting = 'targeting',
}

export interface Preferences {
  id?: string
  analyticsCookies: boolean
  functionalCookies: boolean
  advertisingCookies: boolean
  created?: Date
  modified?: Date
}

export interface Domain {
  id: string
  name: string
  palette: Palette
  content: Content
}

export interface Cookie {
  name: string
  initiatingDomain: string
  category: string
  cookieName: string
}

export interface GetDomainQuery {
  domain: {
    id: string
    name: string
    palette: Palette
    content: Content
  }
}

export interface CreateTransationMutation {
  createTransaction: {
    transaction: Preferences
  }
}

export interface SixFifty {
  authKey: string
  domainId: string
  config: {
    scripts: Cookie[]
  }
}

export interface GetCookieDefintionsResponse {
  domain: {
    id: string
    name: string
    cookies: Cookie[]
  }
}
