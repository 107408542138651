import { gql } from 'graphql-request'
import { ScriptTypes } from './types'

export const BLOCKED_TYPE: 'javascript/blocked' = 'javascript/blocked'

export const backupScripts = {
  blacklisted: [],
}

export const allScripts: Record<ScriptTypes, Array<string>> = {
  [ScriptTypes.advertising]: [],
  [ScriptTypes.analytics]: [],
  [ScriptTypes.functional]: [],
  [ScriptTypes.necessary]: [],
  [ScriptTypes.targeting]: [],
}

export const ACCEPT_ALL: 'sf-accept-all' = 'sf-accept-all'
export const ACCEPT_NONE: 'sf-accept-none' = 'sf-accept-none'
export const SFC_CLOSE: 'sfc-close' = 'sfc-close'
export const GET_DOMAIN = gql`
  query GetDomain {
    domain {
      id
      name
      palette {
        title
        button
        link
      }
      content {
        title
        description
        accept
        decline
        link
        customizeTitle
        necessaryTitle
        necessaryDescription
        functionalTitle
        functionalDescription
        advertisingTitle
        advertisingDescription
        analyticsTitle
        analyticsDescription
        backButton
        saveButton
        policyHref
      }
    }
  }
`
export const CREATE_TRANSACTION = gql`
  mutation CreateTransaction($consenter: UUID!, $ac: Boolean!, $fc: Boolean!, $tc: Boolean!) {
    createTransaction(consenter: $consenter, analyticsCookies: $ac, functionalCookies: $fc, advertisingCookies: $tc) {
      transaction {
        id
        functionalCookies
        analyticsCookies
        advertisingCookies
        created
        modified
      }
    }
  }
`

export const GET_COOKIE_DEFINITIONS = gql`
  query GetCookieDefinitions {
    domain {
      id
      name
      cookies {
        cookieName
        serviceName
        initiatingDomain
        category
      }
    }
  }
`
