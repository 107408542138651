import { Content } from '../types'
import footer from './footer'
import { consenter } from '../consenter'
import { ACCEPT_ALL, ACCEPT_NONE, SFC_CLOSE } from '../variables'

const noticeTemplate = (content: Content) => `
  <div class="sf-consent__content">
      ${
        consenter.requiresConsent()
          ? ``
          : `<div id="${SFC_CLOSE}" class="sf_close"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="17" height="17" viewBox="0 0 17 17">
    <path d="M9.207 8.5l6.646 6.646-0.707 0.707-6.646-6.646-6.646 6.646-0.707-0.707 6.646-6.646-6.647-6.646 0.707-0.707 6.647 6.646 6.646-6.646 0.707 0.707-6.646 6.646z" fill="#000000" />
    </svg></div>`
      }
    <header class="sf-consent__header"><h4 class="sf-consent__header__title">${content.title}</h4></header>
    <div class="modal-main">
      <p class="content-description">${content.description}</p>
      <div>
        <button id="${ACCEPT_NONE}" class="sf-submit default">${content.decline}</button>
        <button id="${ACCEPT_ALL}" class="sf-submit primary">${content.accept}</button>
      </div>
      <div><a onClick="SixFiftyConsent.showPreferences()">${content.link}</a></div>
      ${
        content.policyHref !== ''
          ? `<a id="privacy-policy" href="${content.policyHref}" target="_blank" rel="noreferrer noopener" >Privacy Policy</a>`
          : ``
      }
    </div>
    ${footer}
  </div>
`

export default noticeTemplate
