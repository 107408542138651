import { consenter } from './consenter'
import { BLOCKED_TYPE } from './variables'

export const isHTMLScriptElement = (node: Node): node is HTMLScriptElement => node instanceof HTMLScriptElement

export const isOnBlocklist = (src: string, type: string): boolean =>
  src &&
  (!type || type !== BLOCKED_TYPE) &&
  (!consenter.blacklist || consenter.blacklist.some(pattern => pattern.test(src)))

export const willBeUnblocked = (script: HTMLScriptElement) => {
  const src = script.getAttribute('src')
  return consenter.blacklist && consenter.blacklist.every(entry => !entry.test(src))
}

export function parsedDocumentCookies(): Record<string, string> {
  const documentCookies = document.cookie.split(';')
  const cookies = {}
  documentCookies.forEach(docCookie => {
    const pair = docCookie.split('=')
    cookies[(pair[0] + '').trim()] = unescape(pair.slice(1).join('='))
  })
  return cookies
}
